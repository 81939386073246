import React, { ChangeEvent, FormEvent, useReducer, useRef } from "react"
import { Form, Button } from "react-bootstrap"
import { Input } from "@app/components/Form"
import { H1 } from "@app/components/Typography"
import { formatDate, parseDate } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import fetch from "@app/utils/axios"

export interface TrainerReportsState {
  validated: boolean
  reportTypeKey: string
  entityId?: string
  entityType: string
  errorMessage?: string
  startDate?: Date
  endDate?: Date
  range: any
}
export interface ReportType {
  name: string
  key: string
  entityType:
    | "schedule"
    | "system"
    | "multi_system"
    | "date_range"
    | "system_date_range"
    | "system_no_date_range"
  fileType: "csv" | "xlsx"
}

export type ReportTypes = {
  [index: string]: ReportType
}

const reportTypes: ReportTypes = {
  providersByGroup: {
    name: "Providers by Group",
    key: "providers_by_group",
    entityType: "schedule",
    fileType: "csv",
  },
  schedulersByGroup: {
    name: "Schedulers by Group",
    key: "schedulers_by_group",
    entityType: "schedule",
    fileType: "csv",
  },
  jobsByGroup: {
    name: "Jobs by Group",
    key: "jobs_by_group",
    entityType: "schedule",
    fileType: "csv",
  },
  systemSummary: {
    name: "Summary By System",
    key: "summary_by_system",
    entityType: "system",
    fileType: "csv",
  },
  scheduleDataSummary: {
    name: "User Summary By System",
    key: "user_summary_by_system",
    entityType: "system",
    fileType: "csv",
  },
  systemGroups: {
    name: "Schedule Settings By System",
    key: "schedule_settings_by_system",
    entityType: "system",
    fileType: "csv",
  },
  systemJobs: {
    name: "Jobs By System",
    key: "jobs_by_system",
    entityType: "system",
    fileType: "csv",
  },
  scheduleSummaryBySystem: {
    name: "Schedule Summary By System",
    key: "schedule_summary_by_system",
    entityType: "system_date_range",
    fileType: "csv",
  },
  monthly: {
    name: "Monthly Report",
    key: "monthly",
    entityType: "date_range",
    fileType: "csv",
  },
  monthlyBySystem: {
    name: "Monthly Report By System",
    key: "monthly_report_by_system",
    entityType: "system_no_date_range",
    fileType: "csv",
  },
  monthlyByGroup: {
    name: "Monthly Report By Group",
    key: "monthly_report_by_group",
    entityType: "system_no_date_range",
    fileType: "csv",
  },
  distinctUsersBySystem: {
    name: "Distinct Users By System",
    key: "distinct_users_by_system",
    entityType: "multi_system",
    fileType: "csv",
  },
  providerContactDetailsBySystem: {
    name: "Provider Contact Details By System",
    key: "provider_contact_details_by_system",
    entityType: "system",
    fileType: "xlsx",
  },
}

const DateRangePicker = (props: any) => {
  const { onChange } = props
  const endDateRef = useRef<any>(null)

  const startDate = props.startDate && parseDate(props.startDate)
  const endDate = props.endDate && parseDate(props.endDate)
  const modifiers = { start: startDate, end: endDate }

  const inputStyle = {
    height: "30px",
    border: "1px solid #D8DCE3",
    borderRadius: "7px",
  }

  return (
    <div className="bootstrap4">
      <p>
        {!startDate && !endDate && "Please select the start date."}
        {startDate && !endDate && "Please select the end date."}
        {startDate &&
          endDate &&
          `Selected from ${formatDate(
            startDate,
            (f) => f.localized
          )} to ${formatDate(endDate, (f) => f.localized)}`}
      </p>
      <div className="d-flex align-items-center">
        <Input
          type="date"
          placeholder="From"
          onChange={(date) => onChange(date, "startDate")}
          inputProps={{ required: true, style: inputStyle }}
          dayPickerProps={{
            selectedDays: [startDate, { startDate, endDate }],
            disabledDays: { after: endDate },
            toMonth: endDate,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => {
              endDateRef.current?.getInput().focus()
            },
          }}
        />
        <span className="ml-2 mr-2">-</span>
        <Input
          ref={endDateRef}
          type="date"
          placeholder="To"
          onChange={(date) => onChange(date, "endDate")}
          inputProps={{ required: true, style: inputStyle }}
          dayPickerProps={{
            selectedDays: [startDate, { startDate, endDate }],
            disabledDays: { before: startDate },
            modifiers,
            month: startDate,
            fromMonth: startDate,
            numberOfMonths: 1,
          }}
        />
      </div>
    </div>
  )
}

const TrainerReports: React.FC<any> = (props) => {
  const uuidPattern =
    "[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}"
  const entityIdPattern = "[\\d]{1,8}"
  const multipleEntityIdPattern = "[\\d,]{1,100}"
  const initialState: TrainerReportsState = {
    validated: false,
    reportTypeKey: "providersByGroup",
    entityId: undefined,
    entityType: "schedule",
    startDate: undefined,
    endDate: undefined,
    range: undefined,
  }

  const reducer = (state: any, action: any) => {
    if (action.type === "reset") {
      return { ...initialState }
    }

    const result = { ...state }
    result[action.type] = action.value
    return result
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    validated,
    reportTypeKey,
    entityId,
    errorMessage,
    startDate,
    endDate,
  } = state

  const validate = (
    event: ChangeEvent<HTMLInputElement>,
    prevValue: any,
    callback: (value: React.SetStateAction<string>) => void
  ): void => {
    event.target.checkValidity()
    if (event.currentTarget && event.currentTarget.checkValidity()) {
      callback(event.target.value)
      dispatch({ type: "validated", value: true })
    } else {
      callback(prevValue)
      dispatch({ type: "validated", value: false })
    }
  }

  const validateEntityId = (e: ChangeEvent<HTMLInputElement>) => {
    validate(e, entityId, (changedId) =>
      dispatch({ type: "entityId", value: changedId })
    )
  }

  const onReportTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "entityId", value: "" })
    dispatch({ type: "reportTypeKey", value: e.target.value })
    dispatch({ type: "validated", value: false })
  }

  const onDayChange = (date: Date, type: string) => {
    dispatch({ type: type, value: date })
  }

  const getReportResults = async (
    reportType: ReportType,
    params: any
  ): Promise<any> => {
    let body
    if (
      ["system", "multi_system", "schedule"].includes(reportType.entityType)
    ) {
      body = {
        type: reportType.key,
        entityid: entityId,
      }
    } else if (["system_no_date_range"].includes(reportType.entityType)) {
      body = {
        type: reportType.key,
      }
    } else if (["system_date_range"].includes(reportType.entityType)) {
      body = {
        type: reportType.key,
        entityid: entityId,
        start_date: formatDate(params.startDate),
        end_date: formatDate(params.endDate),
      }
    } else if (["date_range"].includes(reportType.entityType)) {
      body = {
        type: reportType.key,
        start_date: formatDate(params.startDate),
        end_date: formatDate(params.endDate),
      }
    }

    return fetch("/api/v2/reports", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
      data: JSON.stringify(body),
    })
      .then(handleReportResponse)
      .catch((error) => {
        console.error("Error @ TrainerReports#getReportResults", error)
        return error
      })
  }

  const handleReportResponse = async (response: any) => {
    if (response) {
      var newBlob = new Blob([response])
      var navigator: any = window.navigator

      if (navigator?.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      var data = window.URL.createObjectURL(newBlob)
      var link = document.createElement("a")

      link.href = data
      link.target = "_blank"
      link.download = `${formatDate(
        new Date(),
        DateFormatter.humanizedMonthOnly
      )}_${reportTypes[reportTypeKey].key}_${entityId}.${
        reportTypes[reportTypeKey].fileType
      }`
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(data)
      }, 0)
      return
    } else {
      return Promise.reject(response.message)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: "errorMessage", value: "" })

    if (
      (reportTypes[reportTypeKey] && (entityId || (startDate && endDate))) ||
      reportTypes[reportTypeKey].entityType === "system_no_date_range"
    ) {
      getReportResults(reportTypes[reportTypeKey], {
        entityId,
        startDate,
        endDate,
      }).then((response) => {
        if (response && response.error && response.message) {
          dispatch({ type: "errorMessage", value: response.message })
        } else {
          dispatch({ type: "reportTypeKey", value: "" })
          dispatch({ type: "reset" })
        }
      })
    } else {
      dispatch({
        type: "errorMessage",
        value:
          "We cannot find the report type you're looking for, please try refreshing your page",
      })
    }
  }

  const entityType = reportTypes[reportTypeKey]?.entityType

  return (
    <>
      <H1>Generate Report</H1>
      <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
        <h2>{errorMessage}</h2>
        <Form.Group controlId="report.type" className="select-container">
          <Form.Label>Report Type</Form.Label>
          <Form.Control
            required
            as="select"
            placeholder="Report Type"
            value={reportTypeKey}
            name="reportTypeKey"
            onChange={onReportTypeChange}
          >
            {Object.keys(reportTypes).map((reportTypeKey) => (
              <option key={reportTypeKey} value={reportTypeKey}>
                {reportTypes[reportTypeKey].name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {entityType === "schedule" && (
          <Form.Group controlId="report.groupId">
            <Form.Label>Group ID</Form.Label>
            <Form.Control
              required
              title="Value must be a valid UUID or number between 1 and 10 digits long"
              pattern={`${uuidPattern}|${entityIdPattern}`}
              type="text"
              placeholder="Group ID"
              onChange={validateEntityId}
            />
          </Form.Group>
        )}
        {entityType === "system" && (
          <Form.Group controlId="report.clinicId">
            <Form.Label>Clinic ID</Form.Label>
            <Form.Control
              required
              title="Value must be a valid UUID or number between 1 and 10 digits long"
              pattern={`${uuidPattern}|${entityIdPattern}`}
              type="text"
              placeholder="Clinic ID"
              onChange={validateEntityId}
            />
          </Form.Group>
        )}
        {entityType === "multi_system" && (
          <Form.Group controlId="report.clinicId">
            <Form.Label>Clinic IDs</Form.Label>
            <Form.Control
              required
              title="Value must be a valid UUID or number between 1 and 10 digits long"
              pattern={`${multipleEntityIdPattern}|${entityIdPattern}`}
              type="text"
              placeholder="Clinic IDs"
              onChange={validateEntityId}
            />
          </Form.Group>
        )}
        {entityType === "date_range" && (
          <Form.Group controlId="report.dateRange">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={onDayChange}
            />
          </Form.Group>
        )}
        {entityType === "system_date_range" && (
          <Form.Group controlId="report.dateRange">
            <Form.Label>Clinic ID</Form.Label>
            <Form.Control
              required
              title="Value must be a valid UUID or number between 1 and 10 digits long"
              pattern={`${uuidPattern}|${entityIdPattern}`}
              type="text"
              placeholder="Clinic ID"
              onChange={validateEntityId}
              style={{ marginBottom: "10px" }}
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={onDayChange}
            />
          </Form.Group>
        )}
        <Button type="submit">Submit</Button>
      </Form>
    </>
  )
}

export default TrainerReports
