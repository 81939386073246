import { useSelector } from "@app/models"
import {
  GroupedAssignment,
  mapFunc,
} from "@app/services/getGroupedDayAssignments"

export default (date: string) => {
  const {
    calendarConfig: { filterOptions },
    dailyAssignments: { dailyEvents, dailyDraftEvents },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (assignment: GroupedAssignment) => {
    const { providerIds } = filterOptions.providersFilters

    if (providerIds === undefined) {
      return true
    }

    const providerIdsCondition = providerIds.includes(
      assignment.provider.providerid
    )

    const isMultiAssignmentFilterCondition =
      !!assignment.eventid &&
      assignment.additional_event_assignments?.some((additionalAssignment) =>
        providerIds.includes(additionalAssignment.provider.providerid)
      )

    const isMultiDraftAssignmentFilterCondition =
      !!assignment.draft_eventid &&
      assignment.additional_event_assignments?.some(
        (additionalDraftAssignment) =>
          providerIds.includes(additionalDraftAssignment.provider.providerid)
      )

    return (
      providerIdsCondition ||
      isMultiAssignmentFilterCondition ||
      isMultiDraftAssignmentFilterCondition
    )
  }

  const events = dailyEvents[date] || []
  const draftEvents = dailyDraftEvents[date] || []

  return [...events, ...draftEvents]
    .map((assignment) => mapFunc(assignment, filterFunc))
    .filter(filterFunc)
}
