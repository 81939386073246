import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"

export const getIsAssignmentFiltered = (
  providerId: number,
  providerIds: number[] | undefined
) => {
  return !providerIds?.includes(providerId)
}

type AssignmentType = AssignmentBaseType | GroupedAssignment

export const getProviderIds = (
  assignments: AssignmentType | AssignmentType[]
): number[] => {
  const extractProviderIds = (
    assignments?: Array<{
      providerid?: number
      provider?: { providerid?: number }
    }>
  ) => {
    return (
      assignments?.reduce((acc: number[], assignment) => {
        const providerIds: number[] = [
          assignment.providerid,
          assignment.provider?.providerid,
        ].filter((id): id is number => id !== undefined)
        return acc.concat(providerIds)
      }, []) || []
    )
  }

  const processAssignments = (assignments: AssignmentType[]): number[] => {
    const providerIdsFromAssignments = assignments.reduce(
      (acc: number[], assignment: AssignmentType) => {
        return acc.concat(
          extractProviderIds(
            "additional_event_assignments" in assignment
              ? assignment.additional_event_assignments
              : []
          )
        )
      },
      []
    )

    const allProviderIds = assignments.reduce(
      (acc: number[], assignment: AssignmentType) => {
        return acc.concat(
          [
            "providerid" in assignment ? assignment.providerid : undefined,
            "provider" in assignment
              ? assignment.provider?.providerid
              : undefined,
            ...providerIdsFromAssignments,
          ].filter((id): id is number => id !== undefined)
        )
      },
      []
    )

    return Array.from(new Set(allProviderIds))
  }

  if (Array.isArray(assignments)) {
    return processAssignments(assignments)
  } else {
    return processAssignments([assignments])
  }
}
