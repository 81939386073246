import React, { useMemo } from "react"
import css from "./UnassignedElement.module.scss"
import { JobDefaultColor, UnassignedText } from "@app/utils/constants"
import { formatDateToStartEnd } from "@app/utils"
import { useSelector } from "@app/models"
import cx from "classnames"
interface UnassignedElementProps {
  assignment: JobUnassigned
  onClick: (date: string, jobid: number) => void
  date: string
}

export const UnassignedElement = ({
  assignment,
  onClick,
  date,
}: UnassignedElementProps) => {
  const {
    highlightOptions,
    isHighlightedChangesActive,
    rulesConfig: { display_job_times: showJobTimesInCalendar },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { highlightProviders } = highlightOptions

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  if (!assignment) {
    return null
  }

  const { abbrev, color, jobid } = assignment
  const borderColor = color ?? JobDefaultColor

  return (
    <button
      className={cx(css.unassignedWrapper, {
        [css.highlightFadedUnassignedAssignment]:
          providersAreHighlighted || isHighlightedChangesActive,
      })}
      onClick={() => onClick(date, jobid)}
    >
      <div
        className={css.unassignedBorder}
        style={{ borderColor, backgroundColor: borderColor }}
      />
      <div className={css.unassignedDataWrapper}>
        <div className={css.unassignedTitleWrapper}>
          <span className={css.unassignedTitle}>
            {abbrev} - {UnassignedText}
          </span>
          {showJobTimesInCalendar ? (
            <span className={css.unassignedJobTime}>
              {formatDateToStartEnd(assignment.starttime, assignment.endtime)}
            </span>
          ) : null}
        </div>
      </div>
    </button>
  )
}
