import React, { useCallback, useMemo } from "react"
import { useSelector } from "@app/models"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import AssignmentSingleJobElement from "./AssignmentSingleJobElement"
import AssignmentMultipleJobsElement from "./AssignmentMultipleJobsElement"

interface AssignmentJobElementProps {
  assignment: GroupedAssignment
  showModal: () => void
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: GroupedAssignment) => void
  isHighlightedChanges: boolean
}

export const AssignmentJobElement = ({
  assignment,
  showModal,
  quickMode,
  handleDeleteQuickAssign,
  isHighlightedChanges,
}: AssignmentJobElementProps) => {
  const { selectedProvider } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const { jobs } = useSelector((state) => state.groupData)

  const { isUnderstaffedJobsHighlightActive, isHighlightedChangesActive } =
    useSelector((state) => state.calendarEvents.calendarConfig)

  const additionalEventAssignments = assignment.additional_event_assignments

  const isMultiOrSplitAssignment = useMemo(
    () =>
      (additionalEventAssignments && additionalEventAssignments?.length > 0) ||
      (assignment?.additional_split_event_assignments &&
        assignment?.additional_split_event_assignments?.length > 0),
    [assignment?.additional_split_event_assignments, additionalEventAssignments]
  )

  const isJobSelectedInQuickAssignMode = (
    eventAssignment: GroupedAssignment
  ) => {
    const providerId = eventAssignment.provider?.providerid
    if (!selectedProvider) return true
    return providerId === selectedProvider?.providerid
  }

  const isAdditionalJobDisabled = useMemo(() => {
    return additionalEventAssignments?.some((eventAssignment) =>
      isJobSelectedInQuickAssignMode(eventAssignment)
    )
  }, [additionalEventAssignments])

  const isSplitJobDisabled = useMemo(() => {
    if (!assignment.additional_split_event_assignments) return false
    return assignment.additional_split_event_assignments?.some(
      (splitEventAssignment) =>
        isJobSelectedInQuickAssignMode(splitEventAssignment)
    )
  }, [assignment.additional_split_event_assignments])

  const multipleAssignmentSettings = useMemo(
    () =>
      jobs
        .find((job) => job.jobid === assignment.jobid)
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === assignment.edate
        ),
    [jobs, assignment]
  )

  const isAssignmentUnderstaffed = useMemo(() => {
    let currentAssignedCount =
      assignment.additional_event_assignments.length + 1

    if (assignment.draft_eventid) {
      if (multipleAssignmentSettings?.minimum) {
        return (
          isUnderstaffedJobsHighlightActive &&
          currentAssignedCount < multipleAssignmentSettings.minimum
        )
      }
    }

    return (
      isUnderstaffedJobsHighlightActive &&
      multipleAssignmentSettings &&
      currentAssignedCount < multipleAssignmentSettings.minimum
    )
  }, [isUnderstaffedJobsHighlightActive, assignment])

  const getAssignmentUnderstaffedText = useCallback(() => {
    if (assignment.draft_eventid) {
      const minimum = jobs
        .find((job) => job.jobid === assignment.jobid)
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === assignment.edate
        )?.minimum

      const count = assignment.additional_event_assignments.length + 1
      if (minimum) {
        return `${count}/${minimum}`
      }
    }

    const currentAssignedCount =
      assignment.additional_event_assignments.length + 1

    return `${currentAssignedCount}/${multipleAssignmentSettings?.minimum}`
  }, [assignment, jobs])

  const isJobDisabled = isAdditionalJobDisabled || isSplitJobDisabled
  const isHighlightedChangesAssigment =
    isHighlightedChanges && isHighlightedChangesActive

  if (!isMultiOrSplitAssignment) {
    return (
      <AssignmentSingleJobElement
        assignment={assignment}
        showModal={showModal}
        isJobSelectedInQuickAssignMode={isJobSelectedInQuickAssignMode}
        quickMode={quickMode}
        handleDeleteQuickAssign={handleDeleteQuickAssign}
        isAssignmentUnderstaffed={isAssignmentUnderstaffed}
        getAssignmentUnderstaffedText={getAssignmentUnderstaffedText}
        isHighlightedChangesAssigment={isHighlightedChangesAssigment}
      />
    )
  }

  return (
    <AssignmentMultipleJobsElement
      assignment={assignment}
      isJobDisabled={isJobDisabled}
      showModal={showModal}
      quickMode={quickMode}
      isJobSelectedInQuickAssignMode={isJobSelectedInQuickAssignMode}
      isAssignmentUnderstaffed={isAssignmentUnderstaffed}
      getAssignmentUnderstaffedText={getAssignmentUnderstaffedText}
      isHighlightedChangesAssigment={isHighlightedChangesActive}
    />
  )
}
