import { format, parseISO } from "date-fns"
import { formatDateToStartEnd } from "@app/utils"

export const transformsDataMultipleAssignmentJobs = (
  assignments: AssignmentBaseType[],
  flags: ScheduleDateType[],
  jobs: JobAssignment[],
  date: string
): JobDisplayInfo[] => {
  const formattedDate = format(parseISO(date), "yyyy-MM-dd")
  const dayName = format(parseISO(date), "EEEE")

  const flagDateMap = new Map()
  flags.forEach((flag) => {
    const key = `${flag.job.abbrev}-${flag.link_date.link_date}`
    flagDateMap.set(key, flag)
  })

  const allAssignments = jobs.map((job: JobAssignment) => {
    const jobAssignments = assignments.filter(
      (assignment) => assignment.jobid === job.jobid
    )
    const assignment = jobAssignments[0]

    const startTime = assignment
      ? assignment.job.starttime.substring(0, 5)
      : job.starttime.substring(0, 5)
    const endTime = assignment
      ? assignment.job.endtime.substring(0, 5)
      : job.endtime.substring(0, 5)
    const unParsedShiftTime = `${startTime} - ${endTime}`
    const shifttime = formatDateToStartEnd(startTime, endTime)
    const flagKey = `${job.abbrev}-${formattedDate}`
    const flag = flagDateMap.get(flagKey)
    const isFlagged = Boolean(flag)
    const jobDayType = job.job_day_types.find(
      (dayType: JobDayType) => dayType.day === dayName
    )
    const availability = jobDayType && jobDayType.assign !== null

    const split_shift = jobAssignments
      .map((a) =>
        a.split_shift
          ? { ...a.split_shift, eventid: a.eventid, providerid: a.providerid }
          : null
      )
      .filter((split_shift) => split_shift !== null)

    const multipleAssignmentSettings = job?.multipleAssignmentsSettings?.find(
      (setting) => assignment && setting.date === assignment.edate
    )

    return {
      jobid: job.jobid,
      job: job.abbrev,
      abbrev: job.abbrev,
      priority: job.priority,
      eventid: assignment ? assignment.eventid : undefined,
      draft_eventid: assignment ? assignment.draft_eventid : undefined,
      provider: assignment ? [assignment.provider.display_name] : undefined,
      providerid: assignment ? assignment.providerid : undefined,
      starttime: startTime,
      unParsedShiftTime,
      shifttime,
      split_shift,
      splits: split_shift.length,
      assignments: jobAssignments,
      assigments_count: jobAssignments.length,
      max: multipleAssignmentSettings ? multipleAssignmentSettings.maximum : 1,
      type: job.job_type.type,
      flag: isFlagged,
      status: assignment
        ? assignment.draft_eventid
          ? "Draft"
          : "Published"
        : isFlagged
        ? "Published"
        : "",
      edate: assignment?.edate || date,
      color: assignment?.job.color || job?.color,
      multiple_assignments: job.multiple_assignments,
      additional_event_assignments:
        assignment?.additional_event_assignments || [],
      link_dateid: isFlagged ? flag.link_date.link_dateid : null,
      job_days_type: job.job_day_types,
      day_of_week: dayName,
      availability: availability,
      job_typeid: job.job_typeid,
    }
  })

  return allAssignments
}
