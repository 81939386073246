import api, { mutate } from "@app/services/api"
import { cloneDeep } from "lodash"

const getEventKey = (
  startDate: string,
  endDate: string,
  draftMode?: boolean
) => [draftMode ? api.getDraftEvents : api.getAssignments, startDate, endDate]

export const optimisticCreateEvent = (
  event: AssignmentBaseType,
  startDate: string,
  endDate: string,
  draftMode?: boolean
) => {
  mutate(
    getEventKey(startDate, endDate, draftMode),
    (events) => [...events, event],
    false
  )
}

export const optimisticCreateEvents = (
  events: AssignmentBaseType[],
  startDate: string,
  endDate: string,
  draftMode?: boolean
) => {
  mutate(
    getEventKey(startDate, endDate, draftMode),
    (currentEvents) => [...currentEvents, ...events],
    false
  )
}

export const optimisticUpdateEvent = (
  event: AssignmentBaseType,
  startDate: string,
  endDate: string,
  draftMode?: boolean
) => {
  mutate(
    getEventKey(startDate, endDate, draftMode),
    (events) => {
      if (Array.isArray(events)) {
        const eventsCopy = cloneDeep(events)
        const eventReplaceIndex = events.findIndex(
          (prevEvent) =>
            prevEvent.jobid === event.jobid && prevEvent.edate === event.edate
        )
        eventsCopy.splice(eventReplaceIndex, 1, event)
        return eventsCopy
      }
      return events
    },
    false
  )
}

export const optimisticUpdateEvents = (
  events: AssignmentBaseType[],
  startDate: string,
  endDate: string
) => {
  events.forEach((event) => {
    optimisticDeleteEvent(
      { edate: event.edate, jobid: event.jobid },
      startDate,
      endDate,
      Boolean(event.draft_eventid)
    )
  })

  events.forEach((event) => {
    optimisticCreateEvent(
      event,
      startDate,
      endDate,
      Boolean(event.draft_eventid)
    )
  })
}

export const optimisticDeleteEvent = (
  eventInfo: {
    edate: string
    jobid: number
  },
  startDate: string,
  endDate: string,
  draftMode: boolean
) => {
  const { edate, jobid } = eventInfo
  mutate(
    getEventKey(startDate, endDate, draftMode),
    (events) => {
      if (!Array.isArray(events)) return events

      const findEventPredicate = (event: any) => {
        if (event.split_shift) {
          return event.split_shift.job_edate === edate && event.jobid === jobid
        } else {
          return event.edate === edate && event.jobid === jobid
        }
      }

      const deletedEventIndex = events.findIndex(findEventPredicate)
      const deletedEvent = events.find(findEventPredicate)
      if (deletedEventIndex === -1) return events

      const eventsCopy = cloneDeep(events)

      if (deletedEvent.split_shift) {
        return [
          ...eventsCopy.filter((event) => {
            if (event.split_shift) {
              return !(
                event.split_shift.job_edate ===
                  deletedEvent.split_shift.job_edate &&
                event.jobid === deletedEvent.jobid
              )
            }
            return true
          }),
        ]
      }

      eventsCopy.splice(deletedEventIndex, 1)
      return eventsCopy
    },
    false
  )
}
