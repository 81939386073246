import React, { useEffect } from "react"

import { DateFormatter, MonthsAbbrev } from "@app/utils/constants"
import { format, isSameDay } from "date-fns"
import { filterUnassignedJobs } from "@app/services/getUnassignedEvent"
import { useDispatch, useSelector } from "@app/models"
import { IRequest } from "@app/containers/spa/WhiteboardCalendar/data"
import { Flag } from "@material-ui/icons"
import {
  AssignmentElement,
  RequestElement,
  DayOffElement,
  VacationElement,
  UnassignedElement,
  NoteElement,
} from "../CalendarDayElements"
import AddIcon from "@material-ui/icons/Add"
import { getGroupedDayAssignments } from "@app/services/getGroupedDayAssignments"
import { isHighlightedChange } from "@app/services/getAdditionalAssignmentHighlighted"
import api from "@app/services/api"
import useRequest from "@app/utils/request"

import css from "./CalendarGridView.module.scss"
import cx from "classnames"

type Props = {
  day: Date
  startDate: Date
  endDate: Date
  jobs: any[]
  flags: any[]
  dayoffs: Record<string, Dayoff[]>
  requests: any[]
  vacations: any[]
  notes: any[]
  holidays: any[]
  dailyScheduleChanges: any[]
  showModal: (data: AssignmentBaseType) => void
  showModalToNewAssignments: (date: string, jobid: number) => void
  showDateModal: (date: string) => void
  setSelectedRequestid: React.Dispatch<React.SetStateAction<number | undefined>>
  handleNotesModalOpen: (date: string) => void
  handleScheduleChangesModalOpen: (date: string) => void
}

export default ({
  day,
  startDate,
  endDate,
  jobs,
  flags: dayFlags,
  dayoffs: dailyDayoffs,
  requests: dailyRequests,
  vacations: dailyVacations,
  notes: dailyNotes,
  holidays: holidaysByDate,
  dailyScheduleChanges,
  showModal,
  showModalToNewAssignments,
  showDateModal,
  setSelectedRequestid,
  handleNotesModalOpen,
  handleScheduleChangesModalOpen,
}: Props) => {
  const isWithinPeriod = day >= startDate && day < endDate
  const monthAbbrev = MonthsAbbrev[day.getMonth()]

  const dayIndex = format(day, DateFormatter.normal)

  const {
    calendarConfig: {
      isMissingAssignmentsFilterActive,
      isHighlightedChangesActive,
      filterOptions: { providersFilters },
    },
  } = useSelector((state) => state.calendarEvents)

  const { data: events = [], isLoading: eventsLoading } = useRequest(
    day ? [api.getAssignments, dayIndex, dayIndex] : null
  )

  const { data: draftEvents = [], isLoading: draftEventsLoading } = useRequest(
    day ? [api.getDraftEvents, dayIndex, dayIndex] : null
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (events) {
      dispatch.calendarEvents.setDailyEvents({ [dayIndex]: events })
    }
  }, [events])

  useEffect(() => {
    if (draftEvents) {
      dispatch.calendarEvents.setDailyDraftEvents({ [dayIndex]: draftEvents })
    }
  }, [draftEvents])

  const dayAssignments = [...events, ...draftEvents]

  const groupedDayAssignments = getGroupedDayAssignments(dayAssignments, day)

  const unassignedJobs =
    providersFilters.providerIds && providersFilters.providerIds?.length > 0
      ? []
      : filterUnassignedJobs(jobs, dayIndex, dayAssignments)

  let assignmentDetails: JSX.Element[] = []
  let flagDetails: JSX.Element[] = []
  let dayoffDetails: JSX.Element[] = []
  let requestDetails: JSX.Element[] = []
  let vacationDetails: JSX.Element[] = []
  let unassignedDetails: JSX.Element[] = []
  let noteDetails: JSX.Element[] = []

  dayFlags.forEach((flag: ScheduleDateType, index: number) => {
    flagDetails.push(
      <div key={index}>
        <span
          onClick={() =>
            showModalToNewAssignments(flag.link_date.link_date, flag.job.jobid)
          }
          className={css.jobAbbrev}
        >
          {flag.job.abbrev}
        </span>
        <Flag style={{ color: "red" }} className={css.flagIcon} />
      </div>
    )
  })

  groupedDayAssignments.forEach((assignment: AssignmentBaseType) => {
    const splitShiftKey = assignment.split_shift
      ? `-${assignment.split_shift?.starttime}-${assignment.split_shift?.endtime}`
      : ""

    const isAssignmentHighlighted = isHighlightedChange(
      isHighlightedChangesActive,
      dailyScheduleChanges,
      assignment
    )

    assignmentDetails.push(
      <AssignmentElement
        assignment={assignment}
        showModal={showModal}
        key={
          assignment.eventid
            ? `event-${assignment.eventid}-${assignment.job?.jobid}${splitShiftKey}`
            : `draft-${assignment.draft_eventid}-${assignment.job?.jobid}${splitShiftKey}`
        }
        isHighlightedChanges={isAssignmentHighlighted}
      />
    )
  })

  for (const [typeAbbrev, dayoffs] of Object.entries(dailyDayoffs)) {
    dayoffDetails.push(
      <DayOffElement
        dayOffProviderId={
          dayoffs.map((dayOff: any) => dayOff.provider.providerid)?.[0]
        }
        dayOffTypeName={typeAbbrev}
        dayoffProviders={dayoffs
          .map((dayoff: Dayoff) => dayoff.provider.display_name)
          .join(", ")}
        key={`${typeAbbrev}-approvedDayoff`}
      />
    )
  }

  if (dailyVacations.length > 0) {
    vacationDetails.push(
      <VacationElement
        vacationProviderId={
          dailyVacations.map((vacation) => vacation.provider.providerid)?.[0]
        }
        vacationTypeName="v"
        vacationProviders={dailyVacations
          .map((vacation: Vacation) => vacation.provider.display_name)
          .join(", ")}
        key={`${day}-approvedVacation`}
      />
    )
  }
  if (dailyNotes.length > 0) {
    dailyNotes.forEach((dailyNote) => {
      if (dailyNote.show_on_calendar) {
        noteDetails.push(
          <NoteElement
            key={`${dailyNote.noteid}-${dailyNote.userid}`}
            content={dailyNote.note}
          />
        )
      }
    })
  }

  dailyRequests.forEach((request: IRequest) => {
    requestDetails.push(
      <RequestElement
        request={request}
        setSelectedRequestid={setSelectedRequestid}
        key={`${request.requestid}-pendingRequest-${request.jobid}-${request.requestorid}`}
      />
    )
  })

  unassignedJobs.forEach((job: JobUnassigned) => {
    unassignedDetails.push(
      <UnassignedElement
        assignment={job}
        key={job.jobid}
        onClick={showModalToNewAssignments}
        date={dayIndex}
      />
    )
  })

  const renderDetails = () => {
    if (isMissingAssignmentsFilterActive) {
      return <>{unassignedDetails}</>
    }
    return (
      <>
        {assignmentDetails}
        {assignmentDetails.length === 0 && flagDetails.length === 0 && (
          <p className={css.providerName}>
            <span>No Assignments</span>
          </p>
        )}
        {unassignedDetails}
        {flagDetails}
        {dayoffDetails}
        {vacationDetails}
        {requestDetails}
        {noteDetails}
      </>
    )
  }

  const isToday = isSameDay(new Date(day), new Date())

  const dayElement = (
    <td
      key={day.toISOString()}
      className={cx(isWithinPeriod ? css.backgroundDay : css.otherMonthDay, {
        [css.todayOutlined]: isToday,
      })}
    >
      <div className="day-container">
        {eventsLoading || draftEventsLoading ? (
          <div className={css.calendarLoading}>
            <div className={css.spinner}></div>
            <div>Loading...</div>
          </div>
        ) : (
          <>
            <div>
              <div className={css.dayGridHeaderWrapper}>
                <div className={css.dayGridHeaderContent}>
                  <p
                    className={css.dayGridHeaderDate}
                  >{`${monthAbbrev} ${day.getDate()}`}</p>
                  <div className={css.dayGridHeaderHolidaysWrapper}>
                    {holidaysByDate.map((holidayByDate) => (
                      <div key={holidayByDate.holidayId}>
                        {holidayByDate.name}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={css.dayGridHeaderRightContainer}>
                  {dailyScheduleChanges.length > 0 ? (
                    <button
                      onClick={() => handleScheduleChangesModalOpen(dayIndex)}
                      className={css.trackChanges}
                    >
                      <img src="/art/calendar/delta.png" />
                    </button>
                  ) : null}

                  {dailyNotes.length > 0 ? (
                    <button
                      onClick={() => handleNotesModalOpen(dayIndex)}
                      className={css.noteButton}
                    >
                      Notes
                    </button>
                  ) : null}

                  <div className={css.dayGridHeaderAddContainer}>
                    <a
                      onClick={() => showDateModal(dayIndex)}
                      className="hover-fade"
                    >
                      <AddIcon className={css.addIconColor} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="month-details">
              <div>
                <div className="row">
                  <div className="col-xs-12 month-detail-events">
                    <div className={css.assignmentsWrapper}>
                      {renderDetails()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </td>
  )

  return dayElement
}
