import React, { useEffect, useMemo, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { handleApiError, isIE } from "@app/utils"
import cx from "classnames"
import css from "./JobsTableModal.module.scss"
import { format, parseISO } from "date-fns"
import AddIcon from "@material-ui/icons/Add"
import CheckIcon from "@material-ui/icons/Check"
import AvailableProviders from "../AvailableProviders/AvailableProviders"
import JobsTable from "../JobsTableModal/JobsTable"
import { MultipleAssignmentJob } from "@app/containers/spa/WhiteboardCalendar/data"
import useJobTableForm, {
  FormData,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"
import { transformsDataMultipleAssignmentJobs } from "@app/services/transformDataMultipleAssignment"

import NotesModal from "../NotesModal"
import api, { mutate } from "@app/services/api"
import { select, useSelector, useDispatch } from "@app/models"
import { optimisticUpdateEvents } from "../../utils/calendarUpdates"
import useFilterJobs from "../../hooks/useFilterJobs"
import useJobFilterAssignments from "../../hooks/useJobFilterAssignments"

export type ButtonProps = Omit<
  React.ComponentProps<typeof Button>,
  "onClick"
> & {
  text: string
  position?: "left" | "right"
  onClick?: (closeModal: Function) => any
}

// Must pass onHide() to control the show state of parent component
type Props = Omit<React.ComponentProps<typeof Modal>, "onHide"> & {
  onHide: (status?: any) => void
  closeButton?: boolean
  buttons?: ButtonProps[]
  disableBodyScroll?: boolean
  titleAlign?: "left" | "center" | "right"
  escKeyClose?: boolean
  date: string
  currentDate: string
  onCancel?: () => void
  onSave?: () => void
  isModalDateOpen: boolean
  jobsData?: MultipleAssignmentJob[]
}

export type ModalControlProps = Pick<Props, "show" | "onHide">

// Create Modal Container
let modalJobsContainer = document.querySelector(`div.${css.modalJobsContainer}`)
if (!modalJobsContainer) {
  modalJobsContainer = document.createElement("div")
  modalJobsContainer.classList.add(css.modalJobsContainer, "bootstrap4")
  document.body.appendChild(modalJobsContainer)
}

export default (props: Props) => {
  const {
    title,
    onHide,
    className,
    providers = [],
    closeButton = true,
    show = false,
    children,
    buttons = [],
    disableBodyScroll,
    backdrop = "static",
    titleAlign = "left",
    escKeyClose = true,
    date,
    currentDate,
    onSave,
    isModalDateOpen,
    ...rest
  } = props

  const [loadings, setLoadings] = useState<Record<number, boolean>>({})
  const [isNotifyActive, setIsNotifyActive] = useState(false)
  const [isTrackActive, setIsTrackActive] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState<number | undefined>()
  const [showAvailableProviders, setShowAvailableProviders] = useState(false)
  const [jobAvailableName, setJobAvailableName] = useState("")
  const [focusedJobId, setFocusedJobId] = useState<number | null>(null)
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false)
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false)
  const [expandedCount, setExpandedCount] = useState(0)
  const dispatch = useDispatch()

  const {
    calendarType,
    filterOptions: { startDate, endDate },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useJobTableForm()

  const watchFormElements = watch("formElements")

  useEffect(() => {
    if (!isModalDateOpen) {
      setValue("formElements", [])
      setIsTrackActive(false)
      setIsNotifyActive(false)
    }
  }, [isModalDateOpen])

  // TODO: Show better validation errors in modal popup
  useEffect(() => {
    console.log("Errors: ", errors)
  }, [errors])

  const formattedDate = date ? format(parseISO(date), "EEEE, MM/dd/yy") : ""
  const hideModal = (event?: any) => {
    event?.stopPropagation()
    onHide(false)
    dispatch.calendarEvents.getCalendarDataWithoutEvents()
    setExpandedCount(0)
    setOnSubmitting(false)
  }

  const handleShowAvailableProviders = (
    jobId: number | undefined,
    jobName: string
  ) => {
    setSelectedJobId(jobId)
    setShowAvailableProviders(true)
    setJobAvailableName(jobName)
    setFocusedJobId(jobId || null)
  }

  const handleBack = () => {
    setShowAvailableProviders(false)
    if (focusedJobId !== null) {
      setTimeout(() => {
        const element = document.getElementById(`job-${focusedJobId}`)
        if (element) {
          element.focus()
        }
      }, 0)
    }
  }

  const onFormSubmit = (data: FormData) => {
    setOnSubmitting(true)
    api
      .createEventsBatch({
        data: data.formElements,
        track_changes: isTrackActive,
      })
      .then(({ events, draftEvents }) => {
        calendarType === "calendar"
          ? optimisticUpdateEvents([...events, ...draftEvents], date, date)
          : optimisticUpdateEvents(
              [...events, ...draftEvents],
              startDate,
              endDate
            )
        mutate([api.getScheduleChanges, startDate, endDate])
        hideModal()
      }, handleApiError)
  }

  const handleButtonClick = (
    onClick: ((cb: Function) => any) | undefined,
    buttonIndex: number
  ) => {
    const result = onClick?.(hideModal)

    if (result instanceof Promise) {
      // Disable the button until promise resolved
      setLoadings({ ...loadings, [buttonIndex]: true })
      result.finally(() => {
        setLoadings({ ...loadings, [buttonIndex]: false })
        hideModal()
      })
    } else if (result !== false) {
      hideModal()
    }
  }

  const onOpenNoteModal = () => {
    setIsAddNoteModalOpen(true)
  }

  const onCloseNoteModal = () => {
    setIsAddNoteModalOpen(false)
  }

  if (isAddNoteModalOpen) {
    return (
      <NotesModal
        date={formattedDate}
        isModalOpen={isAddNoteModalOpen}
        onCloseModal={onCloseNoteModal}
      />
    )
  }

  const jobsFilteredAssignments = useJobFilterAssignments(date)
  const filteredJobs = useFilterJobs()
  const { flags } = useSelector(select.calendarEvents.filteredCalendarData)

  const jobsData = useMemo(
    () =>
      props.jobsData
        ? props.jobsData
        : ((date
            ? transformsDataMultipleAssignmentJobs(
                jobsFilteredAssignments,
                flags,
                filteredJobs,
                date
              )
            : []) as unknown as MultipleAssignmentJob[]),
    [jobsFilteredAssignments, flags, date, filteredJobs]
  )

  return (
    <Modal
      centered
      animation={false}
      show={isModalDateOpen || showAvailableProviders}
      container={modalJobsContainer as any}
      className={cx(css.modal, className)}
      backdropClassName={css.backdrop}
      backdrop={backdrop}
      onEscapeKeyDown={() => escKeyClose && hideModal()}
      {...rest}
    >
      <Modal.Header className={css.header}>
        <div
          className={cx(css.headerLeft, {
            [css.headerLeftProvidersView]: showAvailableProviders,
          })}
        >
          <Modal.Title className={`text-${titleAlign} ${css.modalTitle}`}>
            <div>
              {!showAvailableProviders ? "Manual Scheduling" : jobAvailableName}
            </div>
            <div className={css.modalSubtitleDivider}>|</div>
            <h3 className={css.modalSubtitle}>{formattedDate}</h3>
          </Modal.Title>

          {!showAvailableProviders && (
            <button
              className={css.headerAddNoteButton}
              onClick={onOpenNoteModal}
            >
              <AddIcon className={css.headerAddNoteButtonIcon} />
              <span>Add Note</span>
            </button>
          )}
        </div>

        <div className={css.headerRight}>
          {closeButton && (
            <Icon
              name="close"
              className={css.closeIcon}
              onClick={showAvailableProviders ? handleBack : hideModal}
            />
          )}
          <div className={css.buttonContainer}>
            {showAvailableProviders ? (
              <Button
                variant="primary"
                className={cx(css.customButton, css.saveButton)}
                onClick={handleBack}
              >
                Back
              </Button>
            ) : (
              <>
                <button
                  className={css.checkboxContainer}
                  onClick={() => setIsNotifyActive(!isNotifyActive)}
                >
                  <div className={css.checkIconContainer}>
                    {isNotifyActive && <CheckIcon className={css.checkIcon} />}
                  </div>
                  <div>Notify of Changes</div>
                </button>
                <button
                  className={css.checkboxContainer}
                  onClick={() => setIsTrackActive(!isTrackActive)}
                >
                  <div className={css.checkIconContainer}>
                    {isTrackActive && <CheckIcon className={css.checkIcon} />}
                  </div>
                  <div>Track Changes</div>
                </button>

                <Button
                  variant="outline-primary"
                  className={`${css.customButton} ${css.cancelButton}`}
                  onClick={() => {
                    props.onCancel?.()
                    setExpandedCount(0)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className={`${css.customButton} ${css.saveButton}`}
                  onClick={handleSubmit(onFormSubmit)}
                  disabled={expandedCount > 0 || onSubmitting}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          overflow: disableBodyScroll
            ? isIE
              ? "visible"
              : "unset"
            : undefined,
        }}
      >
        {showAvailableProviders && selectedJobId ? (
          <AvailableProviders jobId={selectedJobId} edate={date} />
        ) : (
          <JobsTable
            providers={providers}
            data={jobsData as unknown as MultipleAssignmentJob[]}
            date={date}
            currentDate={currentDate}
            onShowAvailableProviders={handleShowAvailableProviders}
            focusedJobId={focusedJobId}
            control={control}
            setValue={setValue}
            watchFormElements={watchFormElements}
            setExpandedCount={setExpandedCount}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {buttons.map((buttonProps, buttonIndex) => {
          const {
            text,
            onClick,
            disabled,
            position,
            className: btnClassName,
            ...restButtonProps
          } = buttonProps

          return (
            <Button
              key={text}
              disabled={disabled || loadings[buttonIndex]}
              onClick={() => handleButtonClick(onClick, buttonIndex)}
              className={cx(btnClassName, { "pull-left": position === "left" })}
              {...restButtonProps}
            >
              {text}
            </Button>
          )
        })}
      </Modal.Footer>
    </Modal>
  )
}
