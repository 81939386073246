export class Role {
  userId: number
  groupId: number
  groupName: string
  roleId: number
  clinicId: number
  clinicName: string
  providerId?: number
  name: string
  schedulerId?: number

  constructor(args: any) {
    this.userId = args.userid
    this.groupId = args.groupid
    this.roleId = args.roleid
    this.clinicId = args.clinicid
    this.clinicName = args.clinic_name
    this.name = args.provider_name ?? args.display_name
    this.providerId = args.providerid
    this.schedulerId = args.schedulerid
    this.groupName = args.group_name
  }

  public get entityId() {
    return this.providerId ? this.providerId : this.schedulerId
  }

  public get roleName(): string {
    const types: { [index: string]: string } = {
      "5": "Trainer",
      "6": "Scheduler",
      "7": "Provider",
    }
    return types[String(this.roleId)]
  }
}
