import { useSelector } from "@app/models"
import {
  GroupedAssignment,
  mapFunc,
} from "@app/services/getGroupedDayAssignments"
import { useMemo } from "react"

export default (date: string) => {
  const {
    calendarConfig: { filterOptions },
    dailyAssignments: { dailyEvents, dailyDraftEvents },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (assignment: GroupedAssignment) => {
    const { jobIds } = filterOptions.jobsFilters

    if (jobIds === undefined) {
      return true
    }

    const jobIdsCondition = jobIds.includes(assignment.job?.jobid)

    return jobIdsCondition
  }

  const events = dailyEvents[date] || []
  const draftEvents = dailyDraftEvents[date] || []

  const data = useMemo(() => {
    return [...events, ...draftEvents]
      .map((assignment) => mapFunc(assignment, filterFunc))
      .filter(filterFunc)
  }, [filterOptions, events, draftEvents])

  return data
}
