import React, { useMemo } from "react"
import { JobDefaultColor } from "@app/utils/constants"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { formatDateToStartEnd } from "@app/utils"
import { AssignmentDot } from "../AssignmentDot"
import { useSelector } from "@app/models"
import { getAdditionalAssignmentHighlighted } from "@app/services/getAdditionalAssignmentHighlighted"
import cx from "classnames"
import css from "./AssignmentElement.module.scss"
import { type Provider } from "../../data"

const areAllAdditionalElementsHighlighted = (
  additionalElements: AssignmentBaseType[] | undefined,
  highlightProviders: Provider[] | undefined
) =>
  additionalElements?.every((additionalElement) =>
    highlightProviders?.some(
      (highlightedProvider) =>
        additionalElement.provider.providerid === highlightedProvider.providerid
    )
  )

interface AssignmentElementProps {
  assignment: GroupedAssignment
  showModal: (data: AssignmentBaseType) => void
  isHighlightedChanges: boolean
}

export const AssignmentElement = ({
  assignment,
  showModal,
  isHighlightedChanges,
}: AssignmentElementProps) => {
  const {
    highlightOptions: { highlightProviders },
    filterOptions: { providersFilters },
    rulesConfig: { display_job_times: showJobTimesInCalendar },
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isAssignmentHighlighted = useMemo(
    () =>
      highlightProviders?.some(
        (highlightedProvider) =>
          assignment.provider.providerid === highlightedProvider.providerid
      ),
    [highlightProviders, assignment.provider.providerid]
  )

  const isSplitAssignmentHighlighted = useMemo(
    () =>
      assignment.additional_split_event_assignments?.some((additionalElement) =>
        highlightProviders?.some(
          (highlightedProvider) =>
            additionalElement.provider.providerid ===
            highlightedProvider.providerid
        )
      ),
    [assignment.additional_split_event_assignments, highlightProviders]
  )

  const additionalEventAssignments = assignment.additional_event_assignments

  const isAdditionalAssignmentHighlighted = useMemo(
    () =>
      additionalEventAssignments?.some((additionalElement) =>
        highlightProviders?.some(
          (highlightedProvider) =>
            additionalElement.provider.providerid ===
            highlightedProvider.providerid
        )
      ),
    [additionalEventAssignments, highlightProviders]
  )

  const showMainAssignmentData = useMemo(
    () =>
      !providersFilters.providerIds?.length ||
      (providersFilters.providerIds &&
        providersFilters.providerIds?.length > 0 &&
        providersFilters.providerIds?.includes(assignment.provider.providerid)),
    [providersFilters, assignment]
  )

  const itemIsHighlighted =
    isAssignmentHighlighted ||
    isSplitAssignmentHighlighted ||
    isAdditionalAssignmentHighlighted ||
    (isHighlightedChangesActive && isHighlightedChanges)

  const isCurrentElementNotHighlighted =
    providersAreHighlighted &&
    !isAssignmentHighlighted &&
    !isSplitAssignmentHighlighted &&
    !isAdditionalAssignmentHighlighted

  const allProvidersAreNotHighlighted = useMemo(() => {
    const additionalSplitEventAssignmentsHighlighted =
      areAllAdditionalElementsHighlighted(
        assignment.additional_split_event_assignments ?? [],
        highlightProviders
      )

    const additionalEventAssignmentsHighlighted =
      areAllAdditionalElementsHighlighted(
        additionalEventAssignments ?? [],
        highlightProviders
      )

    return (
      !isAssignmentHighlighted &&
      (additionalSplitEventAssignmentsHighlighted ||
        !additionalEventAssignmentsHighlighted)
    )
  }, [
    isAssignmentHighlighted,
    assignment.additional_split_event_assignments,
    highlightProviders,
    additionalEventAssignments,
  ])

  const getCustomColor = (assignment: GroupedAssignment) => {
    const providerColor = assignment.provider?.provider_color ?? undefined
    if (isHighlightedChangesActive) {
      return isHighlightedChanges
        ? providerColor ?? JobDefaultColor
        : JobDefaultColor
    }
    return isCurrentElementNotHighlighted
      ? JobDefaultColor
      : providerColor ?? JobDefaultColor
  }

  return (
    <button
      className={cx(css.assignmentWrapper, {
        [css.highlightedAssignment]: itemIsHighlighted,
        [css.highlightedAssignmentDraft]:
          isHighlightedChangesActive &&
          isHighlightedChanges &&
          assignment.draft_eventid,
      })}
      onClick={() => showModal(assignment)}
    >
      <div
        className={cx(css.assignmentBorder, {
          [css.highlightedBorder]:
            providersAreHighlighted || isHighlightedChangesActive,
        })}
        style={{
          borderColor: assignment.job.color ?? JobDefaultColor,
          backgroundColor: !assignment.draft_eventid
            ? assignment.job.color ?? JobDefaultColor
            : "",
        }}
      />
      <div
        className={cx(css.assignmentDataWrapper, {
          [css.highlightedDataWrapper]: itemIsHighlighted,
          [css.disabledAssignmentWrapper]:
            isCurrentElementNotHighlighted ||
            (isHighlightedChangesActive && !isHighlightedChanges),
          [css.disabledAssignmentDataWrapper]:
            isCurrentElementNotHighlighted ||
            (isHighlightedChangesActive && !isHighlightedChanges),
          [css.highlightFadedUnpublishedAssignment]:
            (isCurrentElementNotHighlighted && assignment.draft_eventid) ||
            (isHighlightedChangesActive &&
              assignment.draft_eventid &&
              !isHighlightedChanges),
        })}
        style={{
          backgroundColor: assignment.draft_eventid ? "transparent" : "",
          borderColor: assignment.draft_eventid ? "#DADADA" : "",
        }}
      >
        <div
          className={css.assignmentTitleWrapper}
          style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
        >
          <div className={css.assignmentJobHorizontalWrapper}>
            <span>
              {assignment.job.abbrev}{" "}
              {additionalEventAssignments?.length === 0 &&
              !assignment.split_shift
                ? "-"
                : ""}
            </span>
            {additionalEventAssignments?.length === 0 &&
            !assignment.split_shift ? (
              <div className={css.assignmentJobHorizontalWrapper}>
                <AssignmentDot
                  isDraft={Boolean(assignment.draft_eventid)}
                  customColor={getCustomColor(assignment)}
                />
                <span className={css.assignmentProviderName}>
                  {assignment.provider?.display_name}
                </span>
              </div>
            ) : null}
          </div>

          {(!additionalEventAssignments ||
            additionalEventAssignments.length === 0) &&
          !assignment.split_shift ? (
            <>
              {showJobTimesInCalendar ? (
                <span className={css.assignmentJobTime}>
                  {formatDateToStartEnd(
                    assignment.job.starttime,
                    assignment.job.endtime
                  )}
                </span>
              ) : null}
            </>
          ) : null}
        </div>

        {Boolean(assignment.split_shiftid) === true && (
          <div className={css.assignmentVerticalWrapper}>
            <span className={css.assignmentDescriptionWrapper}>
              Split Shift
            </span>
            <div className={css.assignmentDescriptionWrapper}>
              <div className={css.splitAssignmentVerticalWrapper}>
                <div
                  className={cx(css.splitAssignmentJobHorizontalWrapper, {
                    [css.assignmentDescriptionWrapperFaded]:
                      providersAreHighlighted &&
                      !allProvidersAreNotHighlighted &&
                      !getAdditionalAssignmentHighlighted(
                        assignment.provider.providerid,
                        highlightProviders
                      ),
                  })}
                >
                  <AssignmentDot
                    isDraft={Boolean(assignment.draft_eventid)}
                    customColor={getCustomColor(assignment)}
                  />

                  <div className={css.verticalTextContainer}>
                    <span className={css.splitAssignmentProviderName}>
                      {assignment.provider.display_name}
                    </span>
                    {showJobTimesInCalendar ? (
                      <span>
                        {formatDateToStartEnd(
                          assignment.split_shift.starttime,
                          assignment.split_shift.endtime
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
                {assignment.additional_split_event_assignments?.map(
                  (splitAssignment) => (
                    <div
                      className={cx(
                        css.assignmentDescriptionWrapper,
                        css.assignmentSplitElementBordered,
                        {
                          [css.assignmentDescriptionWrapperFaded]:
                            providersAreHighlighted &&
                            !allProvidersAreNotHighlighted &&
                            !getAdditionalAssignmentHighlighted(
                              splitAssignment.provider.providerid,
                              highlightProviders
                            ),
                        }
                      )}
                      key={`${splitAssignment.providerid}-splitAssignment-${splitAssignment.provider.display_name}-${splitAssignment.split_shift?.starttime}-${splitAssignment.split_shift?.endtime}`}
                    >
                      <div className={css.splitAssignmentJobHorizontalWrapper}>
                        <AssignmentDot
                          isDraft={Boolean(splitAssignment.draft_eventid)}
                          customColor={getCustomColor(splitAssignment)}
                        />
                        <div className={css.verticalTextContainer}>
                          <span className={css.splitAssignmentProviderName}>
                            {splitAssignment.provider.display_name}
                          </span>
                          {showJobTimesInCalendar ? (
                            <span>
                              {formatDateToStartEnd(
                                splitAssignment.split_shift.starttime,
                                splitAssignment.split_shift.endtime
                              )}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {additionalEventAssignments && additionalEventAssignments.length > 0 && (
          <div className={css.assignmentVerticalWrapper}>
            {showJobTimesInCalendar ? (
              <span
                className={css.assignmentDescriptionWrapper}
                style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
              >
                {formatDateToStartEnd(
                  assignment.job.starttime,
                  assignment.job.endtime
                )}
              </span>
            ) : null}

            {showMainAssignmentData ? (
              <div
                className={cx(css.assignmentDescriptionWrapper, {
                  [css.assignmentDescriptionWrapperFaded]:
                    providersAreHighlighted &&
                    !allProvidersAreNotHighlighted &&
                    !getAdditionalAssignmentHighlighted(
                      assignment.provider.providerid,
                      highlightProviders
                    ),
                })}
                style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
              >
                <div className={css.assignmentJobHorizontalWrapper}>
                  <AssignmentDot
                    isDraft={Boolean(assignment.draft_eventid)}
                    customColor={getCustomColor(assignment)}
                  />
                  <span className={css.assignmentProviderName}>
                    {assignment.provider.display_name}
                  </span>
                </div>
              </div>
            ) : null}
            {additionalEventAssignments.map(
              (addAssignment: AssignmentBaseType) => (
                <div
                  className={cx(css.assignmentDescriptionWrapper, {
                    [css.assignmentDescriptionWrapperFaded]:
                      providersAreHighlighted &&
                      !allProvidersAreNotHighlighted &&
                      !getAdditionalAssignmentHighlighted(
                        addAssignment.provider.providerid,
                        highlightProviders
                      ),
                  })}
                  style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
                  key={`${addAssignment.providerid}-addAssignment`}
                >
                  <div className={css.assignmentJobHorizontalWrapper}>
                    <AssignmentDot
                      isDraft={Boolean(addAssignment.draft_eventid)}
                      customColor={getCustomColor(addAssignment)}
                    />
                    <span>{addAssignment.provider.display_name}</span>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </button>
  )
}
