import React, { useState } from "react"

import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import { useCaniuseFeature } from "@app/utils/hooks"
import { DaysOfWeek, DaysOfWeekType } from "@app/utils/constants"
import { useDispatch, useSelector } from "@app/models"

import css from "./ManagePreferences.module.scss"
import CheckIcon from "@material-ui/icons/Check"

interface Props {
  onClose: () => void
}

const ManagePreferences = (props: Props) => {
  const { onClose } = props

  const dispatch = useDispatch()

  const {
    rulesConfig: {
      rules,
      draft_mode_scheduling,
      apply_scheduling_rule,
      calendar_start_date,
      display_job_times,
      compact_list_view,
    },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const [schedulingRules, setSchedulingRules] =
    useState<SchedulingRule[]>(rules)
  const [draftModeScheduling, setDraftModeScheduling] = useState<boolean>(
    draft_mode_scheduling
  )
  const [applySchedulingRule, setApplySchedulingRule] = useState<boolean>(
    apply_scheduling_rule
  )
  const [displayJobTimes, setDisplayJobTimes] =
    useState<boolean>(display_job_times)
  const [compactListView, setCompactListView] =
    useState<boolean>(compact_list_view)

  const [selectedCalendarViewStartDay, setSelectedCalendarViewStartDay] =
    useState<DaysOfWeekType>(DaysOfWeek[calendar_start_date - 1])
  const [applyingPreferencesLoading, setApplyingPreferencesLoading] =
    useState(false)

  const { data: draftModeSettingsEnabled } = useRequest([
    api.getDraftModeSettings,
  ])

  const handleCheckboxChange = (id: string) => {
    const newRules = schedulingRules.map((rule: SchedulingRule) => {
      if (rule.id === id) {
        return { ...rule, enabled: !rule.enabled }
      } else {
        return rule
      }
    })

    setSchedulingRules(newRules)
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCalendarViewStartDay(e.target.value as DaysOfWeekType)
  }

  const handleApplyClick = () => {
    setApplyingPreferencesLoading(true)

    const ids = schedulingRules
      .filter((rule: SchedulingRule) => rule.enabled)
      .map((rule: SchedulingRule) => rule.id)

    api
      .updateSchedulingRules({
        rules: ids,
        draft_mode_scheduling: draftModeScheduling,
        apply_scheduling_rule: applySchedulingRule,
        calendar_start_date:
          DaysOfWeek.indexOf(selectedCalendarViewStartDay) + 1,
        display_job_times: displayJobTimes,
        compact_list_view: compactListView,
      })
      .then((resp) => {
        dispatch.calendarEvents.setSchedulingRules(resp)
        setApplyingPreferencesLoading(false)
        onClose()
      }, handleApiError)
  }

  const draftModeEnabled = useCaniuseFeature("draft_mode", {
    scope: "group",
  })

  return (
    <div className={css.container}>
      <div className={css.rulesContainer}>
        <ul className={css.draftListContainer}>
          {Boolean(draftModeEnabled) &&
            Boolean(
              draftModeSettingsEnabled?.draft_mode_settings?.draft_mode_enabled
            ) && (
              <li className={css.draftListItem}>
                Schedule in Draft Mode
                <div className={css.customCheckbox}>
                  <input
                    type="checkbox"
                    id="draft-mode-scheduling"
                    checked={draftModeScheduling}
                    onChange={() =>
                      setDraftModeScheduling(!draftModeScheduling)
                    }
                    className={css.hiddenCheckbox}
                  />
                  <label
                    htmlFor="draft-mode-scheduling"
                    className={css.checkboxLabel}
                  >
                    {draftModeScheduling && (
                      <CheckIcon className={css.checkIcon} />
                    )}
                  </label>
                </div>
              </li>
            )}

          <li className={css.draftListItem}>
            Display job times
            <div className={css.customCheckbox}>
              <input
                type="checkbox"
                id="apply-show-job-time"
                checked={displayJobTimes}
                onChange={() => setDisplayJobTimes(!displayJobTimes)}
                className={css.hiddenCheckbox}
              />
              <label
                htmlFor="apply-show-job-time"
                className={css.checkboxLabel}
              >
                {displayJobTimes && <CheckIcon className={css.checkIcon} />}
              </label>
            </div>
          </li>
          <li className={css.draftListItem}>
            Apply scheduling assistant rules
            <div className={css.customCheckbox}>
              <input
                type="checkbox"
                id="apply-scheduling-rule"
                checked={applySchedulingRule}
                onChange={() => setApplySchedulingRule(!applySchedulingRule)}
                className={css.hiddenCheckbox}
              />
              <label
                htmlFor="apply-scheduling-rule"
                className={css.checkboxLabel}
              >
                {applySchedulingRule && <CheckIcon className={css.checkIcon} />}
              </label>
            </div>
          </li>
          <li className={css.draftListItem}>
            Compact List View
            <div className={css.customCheckbox}>
              <input
                type="checkbox"
                id="compact-list-view"
                checked={compactListView}
                onChange={() => setCompactListView(!compactListView)}
                className={css.hiddenCheckbox}
              />
              <label htmlFor="compact-list-view" className={css.checkboxLabel}>
                {compactListView && <CheckIcon className={css.checkIcon} />}
              </label>
            </div>
          </li>
          <li className={css.draftListItem}>
            <div>Calendar View Start Day</div>

            <div className={css.selectWrapper}>
              <select
                value={selectedCalendarViewStartDay}
                onChange={handleSelectChange}
                className={css.preferencesSelect}
              >
                {DaysOfWeek.map((dayOfWeek) => (
                  <option key={dayOfWeek} value={dayOfWeek}>
                    {dayOfWeek}
                  </option>
                ))}
              </select>
            </div>
          </li>
        </ul>
        <br />
        <h2 className={css.heading}>
          Enable each rule below that will result in a provider being
          Unavailable for an assignment:
        </h2>
        <div className={css.ruleTable}>
          {schedulingRules?.length === 0 && (
            <div className={css.loadingContainer}>
              <div className={css.spinner}></div>
              <div>Loading...</div>
            </div>
          )}
          <ul className={css.listContainer}>
            {schedulingRules &&
              schedulingRules.map((rule: any) => (
                <li key={rule.id} className={css.listItem}>
                  {rule.title}
                  <div className={css.customCheckbox}>
                    <input
                      type="checkbox"
                      id={`checkbox-${rule.id}`}
                      checked={rule.enabled}
                      onChange={() => handleCheckboxChange(rule.id)}
                      className={css.hiddenCheckbox}
                    />
                    <label
                      htmlFor={`checkbox-${rule.id}`}
                      className={css.checkboxLabel}
                    >
                      {rule.enabled && <CheckIcon className={css.checkIcon} />}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className={css.buttonContainer}>
        <button className={css.cancelButton} onClick={onClose}>
          Cancel
        </button>
        <button
          className={css.applyButton}
          onClick={handleApplyClick}
          disabled={applyingPreferencesLoading}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default ManagePreferences
